/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from '../runtime';
import type { RTMEnrollmentDurationEnum } from './RTMEnrollmentDurationEnum';
import {
  RTMEnrollmentDurationEnumFromJSON,
  RTMEnrollmentDurationEnumFromJSONTyped,
  RTMEnrollmentDurationEnumToJSON,
} from './RTMEnrollmentDurationEnum';
import type { RTMEnrollmentStatusEnum } from './RTMEnrollmentStatusEnum';
import {
  RTMEnrollmentStatusEnumFromJSON,
  RTMEnrollmentStatusEnumFromJSONTyped,
  RTMEnrollmentStatusEnumToJSON,
} from './RTMEnrollmentStatusEnum';
import type { UnenrolledReasonEnum } from './UnenrolledReasonEnum';
import {
  UnenrolledReasonEnumFromJSON,
  UnenrolledReasonEnumFromJSONTyped,
  UnenrolledReasonEnumToJSON,
} from './UnenrolledReasonEnum';
import type { WorkflowPatientEnrollment } from './WorkflowPatientEnrollment';
import {
  WorkflowPatientEnrollmentFromJSON,
  WorkflowPatientEnrollmentFromJSONTyped,
  WorkflowPatientEnrollmentToJSON,
} from './WorkflowPatientEnrollment';

/**
 *
 * @export
 * @interface RTMEnrollment
 */
export interface RTMEnrollment {
  /**
   *
   * @type {RTMEnrollmentStatusEnum}
   * @memberof RTMEnrollment
   */
  status?: RTMEnrollmentStatusEnum;
  /**
   *
   * @type {WorkflowPatientEnrollment}
   * @memberof RTMEnrollment
   */
  readonly smsWorkflowEnrollment: WorkflowPatientEnrollment;
  /**
   *
   * @type {RTMEnrollmentDurationEnum}
   * @memberof RTMEnrollment
   */
  duration?: RTMEnrollmentDurationEnum;
  /**
   *
   * @type {number}
   * @memberof RTMEnrollment
   */
  enrolledBy?: number;
  /**
   *
   * @type {Date}
   * @memberof RTMEnrollment
   */
  unenrolledAt?: Date;
  /**
   *
   * @type {number}
   * @memberof RTMEnrollment
   */
  unenrolledBy?: number;
  /**
   *
   * @type {UnenrolledReasonEnum}
   * @memberof RTMEnrollment
   */
  unenrolledReason?: UnenrolledReasonEnum;
}

/**
 * Check if a given object implements the RTMEnrollment interface.
 */
export function instanceOfRTMEnrollment(value: object): boolean {
  if (!('smsWorkflowEnrollment' in value)) return false;
  return true;
}

export function RTMEnrollmentFromJSON(json: any): RTMEnrollment {
  return RTMEnrollmentFromJSONTyped(json, false);
}

export function RTMEnrollmentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RTMEnrollment {
  if (json == null) {
    return json;
  }
  return {
    status:
      json['status'] == null
        ? undefined
        : RTMEnrollmentStatusEnumFromJSON(json['status']),
    smsWorkflowEnrollment: WorkflowPatientEnrollmentFromJSON(
      json['sms_workflow_enrollment'],
    ),
    duration:
      json['duration'] == null
        ? undefined
        : RTMEnrollmentDurationEnumFromJSON(json['duration']),
    enrolledBy: json['enrolled_by'] == null ? undefined : json['enrolled_by'],
    unenrolledAt:
      json['unenrolled_at'] == null
        ? undefined
        : new Date(json['unenrolled_at']),
    unenrolledBy:
      json['unenrolled_by'] == null ? undefined : json['unenrolled_by'],
    unenrolledReason:
      json['unenrolled_reason'] == null
        ? undefined
        : UnenrolledReasonEnumFromJSON(json['unenrolled_reason']),
  };
}

export function RTMEnrollmentToJSON(value?: RTMEnrollment | null): any {
  if (value == null) {
    return value;
  }
  return {
    status: RTMEnrollmentStatusEnumToJSON(value['status']),
    duration: RTMEnrollmentDurationEnumToJSON(value['duration']),
    enrolled_by: value['enrolledBy'],
    unenrolled_at:
      value['unenrolledAt'] == null
        ? undefined
        : (value['unenrolledAt'] as any).toISOString(),
    unenrolled_by: value['unenrolledBy'],
    unenrolled_reason: UnenrolledReasonEnumToJSON(value['unenrolledReason']),
  };
}
