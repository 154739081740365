import {
  PaletteMode,
  ThemeOptions,
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from '@mui/material';
import { useMemo } from 'react';

import {
  ThemesEnum,
  handleSystemTheme,
  useThemeState,
} from '@/state/theme-state';

const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  palette: {
    mode,
    primary: {
      main: '#0493c3',
    },
    secondary: {
      main: '#ea3b3b',
    },
    background: {
      default: mode === ThemesEnum.light ? '#f9f9f9' : '#121212',
    },
  },
  typography: {
    fontSize: 15,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
  components: {
    MuiTooltip: {
      defaultProps: {
        enterDelay: 1000,
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          cursor: 'pointer',
        },
      },
    },
  },
});

export const ThemeProviderWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { theme } = useThemeState();

  const currentTheme = useMemo(() => {
    const activeTheme = handleSystemTheme(theme);

    return responsiveFontSizes(
      createTheme(getDesignTokens(activeTheme as unknown as PaletteMode)),
    );
  }, [theme]);

  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>;
};
