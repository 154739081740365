export const STORE_SESSION_DATA = 'STORE_SESSION_DATA';
export const RESET_SESSION_DATA = 'RESET_SESSION_DATA';
export const SET_CURRENT_PATIENT_ID = 'SET_CURRENT_PATIENT_ID';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const OPEN_FORGOT_PASSWORD = 'OPEN_FORGOT_PASSWORD';
export const CLOSE_FORGOT_PASSWORD = 'CLOSE_FORGOT_PASSWORD';

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_PENDING = 'SIGNUP_PENDING';

export const CREATE_WORKFLOWS = 'CREATE_WORKFLOWS';
export const CREATE_PROVIDERS = 'CREATE_PROVIDERS';
export const CREATE_PATIENTS = 'CREATE_PATIENTS';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const NEW_PASSWORD = 'NEW_PASSWORD';
export const CONFIRM_PASSWORD = 'CONFIRM_PASSWORD';
export const SUBMIT_PASSWORD = 'SUBMIT_PASSWORD';
export const RESET_PASSWORD_PENDING = 'RESET_PASSWORD_PENDING';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const VERIFY_PENDING = 'VERIFY_PENDING';
export const VERIFY_ERROR = 'VERIFY_ERROR';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';

export const LOADING = 'LOADING';
export const LOADING_PATIENT_LIST = 'LOADING_PATIENT_LIST';

export const UPDATE_HEADER = 'UPDATE_HEADER';
