import { LOADING, LOADING_PATIENT_LIST } from '@/old/state/actions/types';

const initialState = {
  isLoading: false,
};

export const Loader = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        ...action.payload,
      };
    case LOADING_PATIENT_LIST:
      return {
        ...state,
        patientList: action.isLoading,
      };
    default:
      return state;
  }
};
