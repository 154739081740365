import { MoonIcon, SunIcon, SunMoonIcon } from 'lucide-react';
import { useEffect } from 'react';
import { create } from 'zustand';

export const ThemesEnum = {
  light: 'light',
  dark: 'dark',
  system: 'system',
} as const;

export const ThemeLabelsEnum = {
  light: 'Light',
  dark: 'Dark',
  system: 'System',
} as const;

export const ThemeIconsEnum = {
  light: SunIcon,
  dark: MoonIcon,
  system: SunMoonIcon,
} as const;

const themeStorageKey = 'theme';

type Theme = (typeof ThemesEnum)[keyof typeof ThemesEnum];

type ThemeState = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

export const handleSystemTheme = (theme: Theme) => {
  if (theme !== ThemesEnum.system) {
    return theme;
  }

  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? ThemesEnum.dark
    : ThemesEnum.light;
};

export const useThemeState = create<ThemeState>((set) => {
  // Get the theme from localStorage or use the default 'system' theme
  const savedTheme = localStorage.getItem(themeStorageKey) as Theme | null;
  const initialTheme: Theme = savedTheme ?? ThemesEnum.system;

  return {
    theme: initialTheme,
    setTheme: (theme) => {
      set({ theme });
      localStorage.setItem(themeStorageKey, theme);
    },
  };
});

// This hook will sync the theme with the body class, we use it in the app or main components
export const useThemeSync = () => {
  const { theme } = useThemeState();

  useEffect(() => {
    document.body.classList.remove(...Object.values(ThemesEnum));

    const systemTheme = handleSystemTheme(theme);

    document.body.classList.add(systemTheme);
  }, [theme]);
};
