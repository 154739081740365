import { SxProps, Theme } from '@mui/material';
import MuiLinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';

export interface ProgressBarLinearProps extends LinearProgressProps {
  rootStyle: SxProps<Theme>;
}

const LinearProgress = ({
  variant = 'determinate',
  rootStyle,
  value = 0,
}: ProgressBarLinearProps) => (
  <MuiLinearProgress
    sx={{
      height: 15,
      borderRadius: 20,
      ...rootStyle,
      bar: {
        borderRadius: 20,
      },
    }}
    variant={variant}
    value={value}
  />
);

export default LinearProgress;
