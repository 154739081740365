/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { ChatChannel, PatchedChatChannel } from '../models/index';
import {
  ChatChannelFromJSON,
  ChatChannelToJSON,
  PatchedChatChannelFromJSON,
  PatchedChatChannelToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface PatientChatChannelCreateRequest {
  chatChannel?: ChatChannel;
}

export interface PatientChatChannelDestroyRequest {
  channelUrl: string;
}

export interface PatientChatChannelPartialUpdateRequest {
  channelUrl: string;
  patchedChatChannel?: PatchedChatChannel;
}

export interface PatientChatChannelRetrieveRequest {
  channelUrl: string;
}

export interface PatientChatChannelUpdateRequest {
  channelUrl: string;
  chatChannel?: ChatChannel;
}

/**
 *
 */
export class PatientApi extends runtime.BaseAPI {
  /**
   */
  async patientChatChannelCreateRaw(
    requestParameters: PatientChatChannelCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ChatChannel>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/chat-channel/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ChatChannelToJSON(requestParameters['chatChannel']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ChatChannelFromJSON(jsonValue),
    );
  }

  /**
   */
  async patientChatChannelCreate(
    requestParameters: PatientChatChannelCreateRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ChatChannel> {
    const response = await this.patientChatChannelCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async patientChatChannelDestroyRaw(
    requestParameters: PatientChatChannelDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['channelUrl'] == null) {
      throw new runtime.RequiredError(
        'channelUrl',
        'Required parameter "channelUrl" was null or undefined when calling patientChatChannelDestroy().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/chat-channel/{channel_url}/`.replace(
          `{${'channel_url'}}`,
          encodeURIComponent(String(requestParameters['channelUrl'])),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async patientChatChannelDestroy(
    requestParameters: PatientChatChannelDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.patientChatChannelDestroyRaw(requestParameters, initOverrides);
  }

  /**
   */
  async patientChatChannelListRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ChatChannel>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/chat-channel/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(ChatChannelFromJSON),
    );
  }

  /**
   */
  async patientChatChannelList(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ChatChannel>> {
    const response = await this.patientChatChannelListRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async patientChatChannelPartialUpdateRaw(
    requestParameters: PatientChatChannelPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ChatChannel>> {
    if (requestParameters['channelUrl'] == null) {
      throw new runtime.RequiredError(
        'channelUrl',
        'Required parameter "channelUrl" was null or undefined when calling patientChatChannelPartialUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/chat-channel/{channel_url}/`.replace(
          `{${'channel_url'}}`,
          encodeURIComponent(String(requestParameters['channelUrl'])),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedChatChannelToJSON(requestParameters['patchedChatChannel']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ChatChannelFromJSON(jsonValue),
    );
  }

  /**
   */
  async patientChatChannelPartialUpdate(
    requestParameters: PatientChatChannelPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ChatChannel> {
    const response = await this.patientChatChannelPartialUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async patientChatChannelRetrieveRaw(
    requestParameters: PatientChatChannelRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ChatChannel>> {
    if (requestParameters['channelUrl'] == null) {
      throw new runtime.RequiredError(
        'channelUrl',
        'Required parameter "channelUrl" was null or undefined when calling patientChatChannelRetrieve().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/chat-channel/{channel_url}/`.replace(
          `{${'channel_url'}}`,
          encodeURIComponent(String(requestParameters['channelUrl'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ChatChannelFromJSON(jsonValue),
    );
  }

  /**
   */
  async patientChatChannelRetrieve(
    requestParameters: PatientChatChannelRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ChatChannel> {
    const response = await this.patientChatChannelRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async patientChatChannelUpdateRaw(
    requestParameters: PatientChatChannelUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ChatChannel>> {
    if (requestParameters['channelUrl'] == null) {
      throw new runtime.RequiredError(
        'channelUrl',
        'Required parameter "channelUrl" was null or undefined when calling patientChatChannelUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/chat-channel/{channel_url}/`.replace(
          `{${'channel_url'}}`,
          encodeURIComponent(String(requestParameters['channelUrl'])),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ChatChannelToJSON(requestParameters['chatChannel']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ChatChannelFromJSON(jsonValue),
    );
  }

  /**
   */
  async patientChatChannelUpdate(
    requestParameters: PatientChatChannelUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ChatChannel> {
    const response = await this.patientChatChannelUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
