import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { loggedUserIdSelector } from '@/old/state/reducers/login_reducer';
import { Provider, ProviderMetadata } from '@/schema';

import { providerMetadataApi } from './api-instances';

interface MetadataState {
  metadata?: ProviderMetadata;
  setMetadata: (metadata?: ProviderMetadata) => void;
}

export const useMetadataState = create<MetadataState>()(
  devtools(
    (set) => ({
      setMetadata: (metadata) => set({ metadata }),
    }),
    { name: 'metadata-state' },
  ),
);

export const selectProviderMetadata = (state: MetadataState) =>
  state.metadata?.providerMetadata;

export const selectUser = (state: MetadataState) =>
  selectProviderMetadata(state)?.user;

export const selectUserId = (state: MetadataState) => selectUser(state)?.id;

export const selectDefaultClinic = (state: MetadataState) => {
  const defaultClinicName = selectUser(state)?.defaultClinic;

  return state.metadata?.company.clinics.find(
    (clinic) => clinic.name === defaultClinicName,
  );
};

export const selectDefaultWorkflow = (state: MetadataState) =>
  selectProviderMetadata(state)?.defaultWorkflow;

export const selectDefaultWorkflowTag = (state: MetadataState) =>
  selectDefaultWorkflow(state)?.rtmFlowTag;

export const selectWorkflows = (state: MetadataState) =>
  selectProviderMetadata(state)?.workflows ?? [];

export const selectProviders = (state: MetadataState) =>
  [
    ...(state.metadata?.company.clinics
      .flatMap((clinic) => clinic.providers)
      .reduce((acc, provider) => {
        if (!acc.has(provider.user.id) && provider.user.isActive) {
          acc.set(provider.user.id, provider.user);
        }

        return acc;
      }, new Map<Provider['user']['id'], Provider['user']>())
      .values() ?? []),
  ].sort((a, b) => a.fullName.localeCompare(b.fullName));

export const selectClinics = (state: MetadataState) =>
  state.metadata?.company.clinics ?? [];

export const useMetadataSync = () => {
  const loggedUserId: number | null = useSelector(loggedUserIdSelector);

  const { setMetadata } = useMetadataState();

  const { data } = useQuery({
    queryKey: ['metadata'],
    queryFn: () =>
      providerMetadataApi.ptProviderMetadataRetrieve({ id: `${loggedUserId}` }),
    enabled: loggedUserId !== null,
  });

  useEffect(() => {
    if (data) {
      setMetadata(data);
    } else {
      setMetadata();
    }
  }, [data, setMetadata]);
};
