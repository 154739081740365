import { CssBaseline, StyledEngineProvider } from '@mui/material';
import {
  ErrorBoundary as SentryErrorBoundary,
  wrapCreateBrowserRouter,
} from '@sentry/react';
import {
  DefaultOptions,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { Provider } from 'react-redux';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';

import {
  FORGOT_PASSWORD,
  LOGIN,
  LOGOUT,
  REGISTRATION,
  RESET_PASSWORD_URI,
  VERIFY_EMAIL,
} from '@/old/config/app_routes';
import { persistor, store } from '@/old/state/store';
import PrivateRoute from '@/old/views/components/PrivateRoute';
import PageWrapperBase from '@/old/views/pages/PageWrapperBase/PageWrapperBase';
import { ThemeProviderWrapper } from '@/old/views/styles/theme';
import {
  BILLING_URL,
  BRIDGE_APP_URL,
  BRIDGE_SSO_URL,
  INTERACTION_LOG_URL,
  METRICS_URL,
  PATIENT_LIST_URL,
  SSO_AUTH_URL,
  SUPPORT_URL,
} from '@/routes/app-routes';
import { ErrorPage } from '@/routes/error-page';
import { RootLayout } from '@/routes/root-layout';
import { UnauthorizedError } from '@/utils/fetch-utils';
import '@/utils/sentry';

import { AppLayout } from './app-layout';
import { Toaster } from './ui/sonner';
import { TooltipProvider } from './ui/tooltip';

const tanstackQueryRetryOptions: DefaultOptions['queries'] &
  DefaultOptions['mutations'] = {
  retry: (failCount: number, error: Error) => {
    if (error instanceof UnauthorizedError || failCount >= 3) {
      return false;
    }
    return true;
  },
  retryDelay: 1000,
  refetchOnWindowFocus: false,
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      ...tanstackQueryRetryOptions,
    },
    mutations: {
      ...tanstackQueryRetryOptions,
    },
  },
});

const importMap = async (
  modulePromise: Promise<{ default: React.ComponentType | null }>,
) => {
  const { default: Component } = await modulePromise;
  return { Component };
};

const createRouter = wrapCreateBrowserRouter(createBrowserRouter);

const router = createRouter([
  {
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      // Redirects & unwrapped routes
      {
        path: SSO_AUTH_URL,
        lazy: () =>
          import('@/routes/sso-auth').then((m) => ({
            Component: m.SSOAuthPage,
          })),
      },
      {
        path: BRIDGE_SSO_URL,
        lazy: () =>
          import('@/routes/bridge-sso-tile-page').then((m) => ({
            Component: m.BridgeSSOTilePage,
          })),
      },
      {
        path: BRIDGE_APP_URL,
        element: <Navigate to={PATIENT_LIST_URL} />,
      },
      // Public routes
      {
        element: <PageWrapperBase />,
        children: [
          {
            path: LOGIN,
            lazy: () =>
              import('@/routes/login-page').then((m) => ({
                Component: m.LoginPage,
              })),
          },
          {
            path: LOGOUT,
            lazy: () =>
              import('@/routes/login-page').then((m) => ({
                Component: m.LoginPage,
              })),
          },
          {
            path: REGISTRATION,
            lazy: () =>
              importMap(import('@/old/views/components/auth/RegistrationPage')),
          },
          {
            path: FORGOT_PASSWORD,
            lazy: () =>
              importMap(
                import(
                  '@/old/views/components/auth/NewPassword/PasswordResetPage'
                ),
              ),
          },
          {
            path: RESET_PASSWORD_URI,
            lazy: () =>
              importMap(
                import(
                  '@/old/views/components/auth/NewPassword/PasswordResetPage'
                ),
              ),
          },
          {
            path: VERIFY_EMAIL,
            lazy: () =>
              importMap(
                import(
                  '@/old/views/components/auth/EmailVerification/EmailVerification'
                ),
              ),
          },
        ],
      },
      // Private routes
      {
        element: <AppLayout />,
        children: [
          {
            element: <PageWrapperBase isSubLayout />,
            errorElement: <ErrorPage />,
            children: [
              {
                element: <PrivateRoute />,
                children: [
                  {
                    path: PATIENT_LIST_URL,
                    // element: <PatientListPage />,
                    lazy: () =>
                      import('@/routes/patient-list-page').then((m) => ({
                        Component: m.PatientListPage,
                      })),
                  },
                  {
                    path: BILLING_URL,
                    lazy: () =>
                      import('@/routes/billing-page').then((m) => ({
                        Component: m.BillingPage,
                      })),
                  },
                  {
                    path: METRICS_URL,
                    lazy: () =>
                      import('@/routes/metrics-page').then((m) => ({
                        Component: m.MetricsPage,
                      })),
                  },
                  {
                    path: `${INTERACTION_LOG_URL}/:patientId`,
                    lazy: () =>
                      import('@/routes/interaction-log-page').then((m) => ({
                        Component: m.InteractionLogsPage,
                      })),
                  },
                  {
                    path: SUPPORT_URL,
                    lazy: () =>
                      import('@/routes/support-page').then((m) => ({
                        Component: m.SupportPage,
                      })),
                  },
                ],
              },
            ],
          },
        ],
      },
      // Fallback route
      {
        path: '*',
        element: <Navigate to={LOGIN} />,
      },
    ],
  },
]);

export const AppProviders = () => {
  return (
    <SentryErrorBoundary fallback={ErrorPage} showDialog>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <StyledEngineProvider injectFirst>
              <TooltipProvider>
                <ThemeProviderWrapper>
                  <CssBaseline />
                  <Toaster />

                  <RouterProvider router={router} />
                </ThemeProviderWrapper>
              </TooltipProvider>
            </StyledEngineProvider>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </SentryErrorBoundary>
  );
};
