import ProgressBarLinear, { ProgressBarLinearProps } from './ProgressBarLinear';

export const AppLoadingBar = ({
  color,
}: Pick<ProgressBarLinearProps, 'color'>) => (
  <ProgressBarLinear
    variant="query"
    rootStyle={{
      position: 'absolute',
      zIndex: 5000,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      height: 4,
    }}
    color={color}
  />
);
