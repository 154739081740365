/**
 * View Bridge SDK documentation:
 * https://arrowhealth.github.io/bridge-sdk/index.html
 */
let bridgeSDK: BridgeSDK;

/** Dynamically loads the Bridge API if running in an iframe. */
export async function initBridge(): Promise<boolean> {
  // Exit if not running in iframe, assume is not in bridge
  if (window.self === window.top) return false;

  try {
    bridgeSDK = await import('@arrowhealth/bridge-sdk');
    console.log('Bridge SDK Loaded', bridgeSDK);
    window.bridgeApi = bridgeSDK;
    return true;
  } catch (ex) {
    console.error('Could not load the Bridge SDK', ex);
  }

  return false;
}
