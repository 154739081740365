import { useQuery } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash-es';
import { BarChart2Icon, UsersRoundIcon, WalletIcon } from 'lucide-react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router';

import { loggedUserIdSelector } from '@/old/state/reducers/login_reducer';
import ProgressBarLinearContainer from '@/old/views/containers/LinearProgressBarContainer';
import {
  BILLING_URL,
  METRICS_URL,
  PATIENT_LIST_URL,
} from '@/routes/app-routes';
import { billingApi } from '@/state/api-instances';
import { pluralize } from '@/utils/accessibility-utils';
import { reportSentryError } from '@/utils/fetch-utils';
import { cn } from '@/utils/tailwind';

import { BillingTab } from '../state/billing-state';
import { AppHeader } from './app-header';
import { BillingTabMapping } from './billing/billing-table-column-def';
import { SideNavTooltipLink } from './side-nav-tooltip-link';

export const AppLayout = () => {
  const { billingUi, metricsPage } = useFlags();

  const loggedUserId: number | null = useSelector(loggedUserIdSelector);

  const { data } = useQuery({
    queryKey: ['billingNotificationDot', loggedUserId],
    queryFn: async () =>
      billingApi
        .billingBillinginstancesList({
          ...BillingTabMapping[BillingTab.Ready],
          pageSize: 1,
          primaryProviderId: `${loggedUserId}`,
        })
        .catch((err: Error) => {
          reportSentryError('/billing/billinginstances/', err);
          return { count: 0 };
        }),
    enabled: billingUi && loggedUserId !== null,
  });

  const isIntercomVisible = useMemo(() => !isEmpty(window?.Intercom), []);

  return (
    <div className="flex h-full flex-col">
      <ProgressBarLinearContainer />
      <AppHeader />

      <div className={cn('grid grow overflow-hidden md:grid-cols-[auto,1fr]')}>
        <nav
          className="grid auto-rows-[min-content] items-start gap-1 border-r bg-card p-2 text-card-foreground max-md:hidden"
          role="navigation"
          aria-label="Main navigation"
        >
          <SideNavTooltipLink
            to={PATIENT_LIST_URL}
            label="Patients"
            Icon={UsersRoundIcon}
          />

          {billingUi && (
            <SideNavTooltipLink
              to={BILLING_URL}
              label="Billing"
              Icon={WalletIcon}
              showNotificationDot={(data?.count ?? 0) > 0}
              tooltip={
                data?.count ? (
                  <p>
                    <strong>Billing:</strong>
                    <br />
                    You have <strong>{data.count}</strong>{' '}
                    {pluralize(data.count, 'billing', 'billings')} ready to be
                    processed.
                  </p>
                ) : undefined
              }
            />
          )}

          {metricsPage && (
            <SideNavTooltipLink
              to={METRICS_URL}
              label="Metrics"
              Icon={BarChart2Icon}
            />
          )}
        </nav>

        <main
          className={cn('relative overflow-auto bg-muted/40 p-4 pb-16 lg:p-6', {
            'pb-16 lg:pb-16': isIntercomVisible,
          })}
        >
          <Outlet />
        </main>
      </div>
    </div>
  );
};
