// auth
export const LOGIN = '/';
export const LOGOUT = '/logout';
export const REGISTRATION = '/registration/';
export const FORGOT_PASSWORD = '/forgot/password/';
export const RESET_PASSWORD_URI = '/password-reset/:token';
export const VERIFY_EMAIL = '/email-verify/:id/';

// Exercise
export const ASSN_LIST_PATIENT_VIEW = '/patient/routine/';
