/* tslint:disable */

/* eslint-disable */

/**
 * SaRA Health API
 * The schema for SaRA Health backend
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type {
  ChatChannel,
  InteractionLog,
  InteractionLogSerializerRequest,
  PaginatedPatientList,
  PatchedInteractionLogSerializerRequest,
  PatchedPatient,
  PatchedPatientCareTeam,
  PatchedProviderMetadata,
  Patient,
  PatientCareTeam,
  PatientCreate,
  PatientRTMEnrollment,
  ProviderMetadata,
  PtRtmEnrollCreate201Response,
  PtRtmReEnrollCreate200Response,
  PtRtmUnenrollRetrieve200Response,
} from '../models/index';
import {
  ChatChannelFromJSON,
  ChatChannelToJSON,
  InteractionLogFromJSON,
  InteractionLogSerializerRequestFromJSON,
  InteractionLogSerializerRequestToJSON,
  InteractionLogToJSON,
  PaginatedPatientListFromJSON,
  PaginatedPatientListToJSON,
  PatchedInteractionLogSerializerRequestFromJSON,
  PatchedInteractionLogSerializerRequestToJSON,
  PatchedPatientCareTeamFromJSON,
  PatchedPatientCareTeamToJSON,
  PatchedPatientFromJSON,
  PatchedPatientToJSON,
  PatchedProviderMetadataFromJSON,
  PatchedProviderMetadataToJSON,
  PatientCareTeamFromJSON,
  PatientCareTeamToJSON,
  PatientCreateFromJSON,
  PatientCreateToJSON,
  PatientFromJSON,
  PatientRTMEnrollmentFromJSON,
  PatientRTMEnrollmentToJSON,
  PatientToJSON,
  ProviderMetadataFromJSON,
  ProviderMetadataToJSON,
  PtRtmEnrollCreate201ResponseFromJSON,
  PtRtmEnrollCreate201ResponseToJSON,
  PtRtmReEnrollCreate200ResponseFromJSON,
  PtRtmReEnrollCreate200ResponseToJSON,
  PtRtmUnenrollRetrieve200ResponseFromJSON,
  PtRtmUnenrollRetrieve200ResponseToJSON,
} from '../models/index';
import * as runtime from '../runtime';

export interface PtInteractionLogCreateRequest {
  interactionLogSerializerRequest: InteractionLogSerializerRequest;
}

export interface PtInteractionLogDestroyRequest {
  id: number;
}

export interface PtInteractionLogListRequest {
  durationSecs?: number;
  durationSecsGt?: number;
  durationSecsLt?: number;
  endDatetime?: Date;
  interactedDatetime?: Date;
  interactedDatetimeGte?: Date;
  interactedDatetimeLte?: Date;
  patient?: number;
  provider?: number;
  startDatetime?: Date;
  types?: string;
}

export interface PtInteractionLogPartialUpdateRequest {
  id: number;
  patchedInteractionLogSerializerRequest?: PatchedInteractionLogSerializerRequest;
}

export interface PtInteractionLogRetrieveRequest {
  id: number;
}

export interface PtInteractionLogUpdateRequest {
  id: number;
  interactionLogSerializerRequest: InteractionLogSerializerRequest;
}

export interface PtPatientListCreateRequest {
  patient?: Patient;
}

export interface PtPatientListDestroyRequest {
  id: string;
}

export interface PtPatientListListRequest {
  birthDate?: Date;
  currentIntlogSecsMax?: number;
  currentIntlogSecsMin?: number;
  currentRtmcycleResponsesCountMax?: number;
  currentRtmcycleResponsesCountMin?: number;
  hasSynchronousAction?: boolean;
  isCurrentlyEnrolled?: boolean;
  ordering?: string;
  page?: number;
  pageSize?: number;
  providerId?: number;
  search?: string;
  sendbirdChannelUrls?: Array<string>;
}

export interface PtPatientListPartialUpdateRequest {
  id: string;
  patchedPatient?: PatchedPatient;
}

export interface PtPatientListRetrieveRequest {
  id: string;
}

export interface PtPatientListUpdateRequest {
  id: string;
  patient?: Patient;
}

export interface PtProviderMetadataCreateRequest {
  providerMetadata?: ProviderMetadata;
}

export interface PtProviderMetadataDestroyRequest {
  id: string;
}

export interface PtProviderMetadataInviteToChatChannelCreateRequest {
  id: string;
  chatChannel?: ChatChannel;
}

export interface PtProviderMetadataPartialUpdateRequest {
  id: string;
  patchedProviderMetadata?: PatchedProviderMetadata;
}

export interface PtProviderMetadataRetrieveRequest {
  id: string;
}

export interface PtProviderMetadataUpdateRequest {
  id: string;
  providerMetadata?: ProviderMetadata;
}

export interface PtRtmEnrollCreateRequest {
  patientCreate: PatientCreate;
}

export interface PtRtmReEnrollCreateRequest {
  patientId: string;
  patientRTMEnrollment: PatientRTMEnrollment;
}

export interface PtRtmUnenrollRetrieveRequest {
  patientId: number;
}

export interface PtUpdateCareTeamPartialUpdateRequest {
  userId: number;
  patchedPatientCareTeam?: PatchedPatientCareTeam;
}

export interface PtUpdateCareTeamUpdateRequest {
  userId: number;
  patientCareTeam: PatientCareTeam;
}

/**
 *
 */
export class PtApi extends runtime.BaseAPI {
  /**
   */
  async ptInteractionLogCreateRaw(
    requestParameters: PtInteractionLogCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<InteractionLog>> {
    if (requestParameters['interactionLogSerializerRequest'] == null) {
      throw new runtime.RequiredError(
        'interactionLogSerializerRequest',
        'Required parameter "interactionLogSerializerRequest" was null or undefined when calling ptInteractionLogCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/interaction-log/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: InteractionLogSerializerRequestToJSON(
          requestParameters['interactionLogSerializerRequest'],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InteractionLogFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptInteractionLogCreate(
    requestParameters: PtInteractionLogCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<InteractionLog> {
    const response = await this.ptInteractionLogCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptInteractionLogDestroyRaw(
    requestParameters: PtInteractionLogDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptInteractionLogDestroy().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/interaction-log/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async ptInteractionLogDestroy(
    requestParameters: PtInteractionLogDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.ptInteractionLogDestroyRaw(requestParameters, initOverrides);
  }

  /**
   */
  async ptInteractionLogListRaw(
    requestParameters: PtInteractionLogListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<InteractionLog>>> {
    const queryParameters: any = {};

    if (requestParameters['durationSecs'] != null) {
      queryParameters['duration_secs'] = requestParameters['durationSecs'];
    }

    if (requestParameters['durationSecsGt'] != null) {
      queryParameters['duration_secs__gt'] =
        requestParameters['durationSecsGt'];
    }

    if (requestParameters['durationSecsLt'] != null) {
      queryParameters['duration_secs__lt'] =
        requestParameters['durationSecsLt'];
    }

    if (requestParameters['endDatetime'] != null) {
      queryParameters['end_datetime'] = (
        requestParameters['endDatetime'] as any
      ).toISOString();
    }

    if (requestParameters['interactedDatetime'] != null) {
      queryParameters['interacted_datetime'] = (
        requestParameters['interactedDatetime'] as any
      ).toISOString();
    }

    if (requestParameters['interactedDatetimeGte'] != null) {
      queryParameters['interacted_datetime__gte'] = (
        requestParameters['interactedDatetimeGte'] as any
      ).toISOString();
    }

    if (requestParameters['interactedDatetimeLte'] != null) {
      queryParameters['interacted_datetime__lte'] = (
        requestParameters['interactedDatetimeLte'] as any
      ).toISOString();
    }

    if (requestParameters['patient'] != null) {
      queryParameters['patient'] = requestParameters['patient'];
    }

    if (requestParameters['provider'] != null) {
      queryParameters['provider'] = requestParameters['provider'];
    }

    if (requestParameters['startDatetime'] != null) {
      queryParameters['start_datetime'] = (
        requestParameters['startDatetime'] as any
      ).toISOString();
    }

    if (requestParameters['types'] != null) {
      queryParameters['types'] = requestParameters['types'];
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/interaction-log/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(InteractionLogFromJSON),
    );
  }

  /**
   */
  async ptInteractionLogList(
    requestParameters: PtInteractionLogListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<InteractionLog>> {
    const response = await this.ptInteractionLogListRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptInteractionLogPartialUpdateRaw(
    requestParameters: PtInteractionLogPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<InteractionLog>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptInteractionLogPartialUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/interaction-log/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedInteractionLogSerializerRequestToJSON(
          requestParameters['patchedInteractionLogSerializerRequest'],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InteractionLogFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptInteractionLogPartialUpdate(
    requestParameters: PtInteractionLogPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<InteractionLog> {
    const response = await this.ptInteractionLogPartialUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptInteractionLogRetrieveRaw(
    requestParameters: PtInteractionLogRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<InteractionLog>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptInteractionLogRetrieve().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/interaction-log/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InteractionLogFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptInteractionLogRetrieve(
    requestParameters: PtInteractionLogRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<InteractionLog> {
    const response = await this.ptInteractionLogRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptInteractionLogUpdateRaw(
    requestParameters: PtInteractionLogUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<InteractionLog>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptInteractionLogUpdate().',
      );
    }

    if (requestParameters['interactionLogSerializerRequest'] == null) {
      throw new runtime.RequiredError(
        'interactionLogSerializerRequest',
        'Required parameter "interactionLogSerializerRequest" was null or undefined when calling ptInteractionLogUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/interaction-log/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: InteractionLogSerializerRequestToJSON(
          requestParameters['interactionLogSerializerRequest'],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      InteractionLogFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptInteractionLogUpdate(
    requestParameters: PtInteractionLogUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<InteractionLog> {
    const response = await this.ptInteractionLogUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptPatientListCreateRaw(
    requestParameters: PtPatientListCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Patient>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/patient-list/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PatientToJSON(requestParameters['patient']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PatientFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptPatientListCreate(
    requestParameters: PtPatientListCreateRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Patient> {
    const response = await this.ptPatientListCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptPatientListDestroyRaw(
    requestParameters: PtPatientListDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptPatientListDestroy().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/patient-list/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async ptPatientListDestroy(
    requestParameters: PtPatientListDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.ptPatientListDestroyRaw(requestParameters, initOverrides);
  }

  /**
   */
  async ptPatientListListRaw(
    requestParameters: PtPatientListListRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaginatedPatientList>> {
    const queryParameters: any = {};

    if (requestParameters['birthDate'] != null) {
      queryParameters['birth_date'] = (requestParameters['birthDate'] as any)
        .toISOString()
        .substring(0, 10);
    }

    if (requestParameters['currentIntlogSecsMax'] != null) {
      queryParameters['current_intlog_secs_max'] =
        requestParameters['currentIntlogSecsMax'];
    }

    if (requestParameters['currentIntlogSecsMin'] != null) {
      queryParameters['current_intlog_secs_min'] =
        requestParameters['currentIntlogSecsMin'];
    }

    if (requestParameters['currentRtmcycleResponsesCountMax'] != null) {
      queryParameters['current_rtmcycle_responses_count_max'] =
        requestParameters['currentRtmcycleResponsesCountMax'];
    }

    if (requestParameters['currentRtmcycleResponsesCountMin'] != null) {
      queryParameters['current_rtmcycle_responses_count_min'] =
        requestParameters['currentRtmcycleResponsesCountMin'];
    }

    if (requestParameters['hasSynchronousAction'] != null) {
      queryParameters['has_synchronous_action'] =
        requestParameters['hasSynchronousAction'];
    }

    if (requestParameters['isCurrentlyEnrolled'] != null) {
      queryParameters['is_currently_enrolled'] =
        requestParameters['isCurrentlyEnrolled'];
    }

    if (requestParameters['ordering'] != null) {
      queryParameters['ordering'] = requestParameters['ordering'];
    }

    if (requestParameters['page'] != null) {
      queryParameters['page'] = requestParameters['page'];
    }

    if (requestParameters['pageSize'] != null) {
      queryParameters['page_size'] = requestParameters['pageSize'];
    }

    if (requestParameters['providerId'] != null) {
      queryParameters['provider_id'] = requestParameters['providerId'];
    }

    if (requestParameters['search'] != null) {
      queryParameters['search'] = requestParameters['search'];
    }

    if (requestParameters['sendbirdChannelUrls'] != null) {
      queryParameters['sendbird_channel_urls'] = requestParameters[
        'sendbirdChannelUrls'
      ]!.join(runtime.COLLECTION_FORMATS['csv']);
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/patient-list/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaginatedPatientListFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptPatientListList(
    requestParameters: PtPatientListListRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaginatedPatientList> {
    const response = await this.ptPatientListListRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptPatientListPartialUpdateRaw(
    requestParameters: PtPatientListPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Patient>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptPatientListPartialUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/patient-list/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedPatientToJSON(requestParameters['patchedPatient']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PatientFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptPatientListPartialUpdate(
    requestParameters: PtPatientListPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Patient> {
    const response = await this.ptPatientListPartialUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptPatientListRetrieveRaw(
    requestParameters: PtPatientListRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Patient>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptPatientListRetrieve().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/patient-list/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PatientFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptPatientListRetrieve(
    requestParameters: PtPatientListRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Patient> {
    const response = await this.ptPatientListRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptPatientListUpdateRaw(
    requestParameters: PtPatientListUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Patient>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptPatientListUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/patient-list/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: PatientToJSON(requestParameters['patient']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PatientFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptPatientListUpdate(
    requestParameters: PtPatientListUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Patient> {
    const response = await this.ptPatientListUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptProviderMetadataCreateRaw(
    requestParameters: PtProviderMetadataCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ProviderMetadata>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/provider-metadata/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ProviderMetadataToJSON(requestParameters['providerMetadata']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProviderMetadataFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptProviderMetadataCreate(
    requestParameters: PtProviderMetadataCreateRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ProviderMetadata> {
    const response = await this.ptProviderMetadataCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptProviderMetadataDestroyRaw(
    requestParameters: PtProviderMetadataDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptProviderMetadataDestroy().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/provider-metadata/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async ptProviderMetadataDestroy(
    requestParameters: PtProviderMetadataDestroyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.ptProviderMetadataDestroyRaw(requestParameters, initOverrides);
  }

  /**
   * We directly go to Sendbird and add the user ot the chat without going through the full SaRA invite process since we dont want to add the provider to the patient\'s careteam in this case.
   */
  async ptProviderMetadataInviteToChatChannelCreateRaw(
    requestParameters: PtProviderMetadataInviteToChatChannelCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptProviderMetadataInviteToChatChannelCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/provider-metadata/{id}/invite-to-chat-channel/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ChatChannelToJSON(requestParameters['chatChannel']),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * We directly go to Sendbird and add the user ot the chat without going through the full SaRA invite process since we dont want to add the provider to the patient\'s careteam in this case.
   */
  async ptProviderMetadataInviteToChatChannelCreate(
    requestParameters: PtProviderMetadataInviteToChatChannelCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.ptProviderMetadataInviteToChatChannelCreateRaw(
      requestParameters,
      initOverrides,
    );
  }

  /**
   */
  async ptProviderMetadataListRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ProviderMetadata>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/provider-metadata/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(ProviderMetadataFromJSON),
    );
  }

  /**
   */
  async ptProviderMetadataList(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ProviderMetadata>> {
    const response = await this.ptProviderMetadataListRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async ptProviderMetadataPartialUpdateRaw(
    requestParameters: PtProviderMetadataPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ProviderMetadata>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptProviderMetadataPartialUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/provider-metadata/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedProviderMetadataToJSON(
          requestParameters['patchedProviderMetadata'],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProviderMetadataFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptProviderMetadataPartialUpdate(
    requestParameters: PtProviderMetadataPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ProviderMetadata> {
    const response = await this.ptProviderMetadataPartialUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptProviderMetadataRetrieveRaw(
    requestParameters: PtProviderMetadataRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ProviderMetadata>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptProviderMetadataRetrieve().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/provider-metadata/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProviderMetadataFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptProviderMetadataRetrieve(
    requestParameters: PtProviderMetadataRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ProviderMetadata> {
    const response = await this.ptProviderMetadataRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptProviderMetadataUpdateRaw(
    requestParameters: PtProviderMetadataUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ProviderMetadata>> {
    if (requestParameters['id'] == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling ptProviderMetadataUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/provider-metadata/{id}/`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters['id'])),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ProviderMetadataToJSON(requestParameters['providerMetadata']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProviderMetadataFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptProviderMetadataUpdate(
    requestParameters: PtProviderMetadataUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ProviderMetadata> {
    const response = await this.ptProviderMetadataUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Enroll a new patient in RTM.  Responses: - 201: Patient enrolled in RTM - 400: Bad request - 402: Phone number already taken
   */
  async ptRtmEnrollCreateRaw(
    requestParameters: PtRtmEnrollCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PtRtmEnrollCreate201Response>> {
    if (requestParameters['patientCreate'] == null) {
      throw new runtime.RequiredError(
        'patientCreate',
        'Required parameter "patientCreate" was null or undefined when calling ptRtmEnrollCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/rtm-enroll/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PatientCreateToJSON(requestParameters['patientCreate']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PtRtmEnrollCreate201ResponseFromJSON(jsonValue),
    );
  }

  /**
   * Enroll a new patient in RTM.  Responses: - 201: Patient enrolled in RTM - 400: Bad request - 402: Phone number already taken
   */
  async ptRtmEnrollCreate(
    requestParameters: PtRtmEnrollCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PtRtmEnrollCreate201Response> {
    const response = await this.ptRtmEnrollCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptRtmReEnrollCreateRaw(
    requestParameters: PtRtmReEnrollCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PtRtmReEnrollCreate200Response>> {
    if (requestParameters['patientId'] == null) {
      throw new runtime.RequiredError(
        'patientId',
        'Required parameter "patientId" was null or undefined when calling ptRtmReEnrollCreate().',
      );
    }

    if (requestParameters['patientRTMEnrollment'] == null) {
      throw new runtime.RequiredError(
        'patientRTMEnrollment',
        'Required parameter "patientRTMEnrollment" was null or undefined when calling ptRtmReEnrollCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/rtm-re-enroll/{patient_id}`.replace(
          `{${'patient_id'}}`,
          encodeURIComponent(String(requestParameters['patientId'])),
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PatientRTMEnrollmentToJSON(
          requestParameters['patientRTMEnrollment'],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PtRtmReEnrollCreate200ResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptRtmReEnrollCreate(
    requestParameters: PtRtmReEnrollCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PtRtmReEnrollCreate200Response> {
    const response = await this.ptRtmReEnrollCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async ptRtmUnenrollRetrieveRaw(
    requestParameters: PtRtmUnenrollRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PtRtmUnenrollRetrieve200Response>> {
    if (requestParameters['patientId'] == null) {
      throw new runtime.RequiredError(
        'patientId',
        'Required parameter "patientId" was null or undefined when calling ptRtmUnenrollRetrieve().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/rtm-unenroll/{patient_id}`.replace(
          `{${'patient_id'}}`,
          encodeURIComponent(String(requestParameters['patientId'])),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PtRtmUnenrollRetrieve200ResponseFromJSON(jsonValue),
    );
  }

  /**
   */
  async ptRtmUnenrollRetrieve(
    requestParameters: PtRtmUnenrollRetrieveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PtRtmUnenrollRetrieve200Response> {
    const response = await this.ptRtmUnenrollRetrieveRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Mixin to filter patients or users based on the provider\'s clinics. Also adds a custom permission to only allow providers to access the view.
   */
  async ptUpdateCareTeamPartialUpdateRaw(
    requestParameters: PtUpdateCareTeamPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PatientCareTeam>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling ptUpdateCareTeamPartialUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/update-care-team/{user_id}`.replace(
          `{${'user_id'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchedPatientCareTeamToJSON(
          requestParameters['patchedPatientCareTeam'],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PatientCareTeamFromJSON(jsonValue),
    );
  }

  /**
   * Mixin to filter patients or users based on the provider\'s clinics. Also adds a custom permission to only allow providers to access the view.
   */
  async ptUpdateCareTeamPartialUpdate(
    requestParameters: PtUpdateCareTeamPartialUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PatientCareTeam> {
    const response = await this.ptUpdateCareTeamPartialUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * Mixin to filter patients or users based on the provider\'s clinics. Also adds a custom permission to only allow providers to access the view.
   */
  async ptUpdateCareTeamUpdateRaw(
    requestParameters: PtUpdateCareTeamUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PatientCareTeam>> {
    if (requestParameters['userId'] == null) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter "userId" was null or undefined when calling ptUpdateCareTeamUpdate().',
      );
    }

    if (requestParameters['patientCareTeam'] == null) {
      throw new runtime.RequiredError(
        'patientCareTeam',
        'Required parameter "patientCareTeam" was null or undefined when calling ptUpdateCareTeamUpdate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtHeaderAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    if (
      this.configuration &&
      (this.configuration.username !== undefined ||
        this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' +
        btoa(this.configuration.username + ':' + this.configuration.password);
    }
    if (this.configuration && this.configuration.apiKey) {
      headerParameters['Authorization'] =
        await this.configuration.apiKey('Authorization'); // tokenAuth authentication
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('jwtAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pt/update-care-team/{user_id}`.replace(
          `{${'user_id'}}`,
          encodeURIComponent(String(requestParameters['userId'])),
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: PatientCareTeamToJSON(requestParameters['patientCareTeam']),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PatientCareTeamFromJSON(jsonValue),
    );
  }

  /**
   * Mixin to filter patients or users based on the provider\'s clinics. Also adds a custom permission to only allow providers to access the view.
   */
  async ptUpdateCareTeamUpdate(
    requestParameters: PtUpdateCareTeamUpdateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PatientCareTeam> {
    const response = await this.ptUpdateCareTeamUpdateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
