import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { LOGIN } from '@/old/config/app_routes';

const PrivateRoute = () => {
  const location = useLocation();
  const token = useSelector(
    (state: { Session?: { token?: string } }) => state?.Session?.token,
  );

  if (!token) {
    return <Navigate to={LOGIN} state={{ from: location.pathname }} />;
  }

  return <Outlet />;
};

export default PrivateRoute;
