import { useSelector } from 'react-redux';

import { AppLoadingBar } from '@/old/views/components/ProgressBarLinear/AppLoadingBar';

const ProgressBarLinearContainer = () => {
  const isLoading = useSelector((state) => state.Loader.isLoading);

  return isLoading && <AppLoadingBar color="primary" />;
};

export default ProgressBarLinearContainer;
