const SegmentIO = {
  identify: (user) => {
    window.analytics.identify(user.id, {
      username: user.username,
    });
  },
  page: () => {
    if (window.analytics) window.analytics.page();
  },
};

export default SegmentIO;
